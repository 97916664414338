import { Component } from '@angular/core';

@Component({
  selector: 'app-actuality',
  templateUrl: './actuality.component.html',
  styleUrls: ['./actuality.component.scss']
})
export class ActualityComponent {

}
