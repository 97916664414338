<!-- HEADER -->
<div class="reglementation-home-area">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="choice-home-content">
                    <h1>Réglementation</h1>
                    <p class="text-white">
                        DMT Recyclage est une entreprise spécialisée dans la
                        collecte et le recyclage des déchets.
                    </p>
                </div>
            </div>
        </div>
    </div>
</div>

<section id="services" class="one-service-area pt-100">
    <div class="container">
        <div class="one-section-title">
            <span class="sub-title text-uppercase">réglementation</span>
            <h2>Chronologie</h2>
            <p>
                Voici une liste détaillée des dates clés concernant le tri des
                déchets, y compris les obligations pour les entreprises, les
                bureaux, la restauration et les chantiers :
            </p>
        </div>
    </div>
</section>

<div class="faq-style-area pb-100">
    <div class="container">
        <div class="faq-style-accordion">
            <div class="accordion" id="FaqAccordion">
                <div class="accordion-item">
                    <button
                        class="accordion-button"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseOne"
                        aria-expanded="true"
                        aria-controls="collapseOne"
                    >
                        1992 : Directive européenne sur l'incinération des déchets
                    </button>
                    <div
                        id="collapseOne"
                        class="accordion-collapse collapse show"
                        data-bs-parent="#FaqAccordion"
                    >
                        <div class="accordion-body">
                            <p>
                                En 1992, l'Union européenne a introduit la directive 92/100/CEE sur l'incinération des déchets, établissant des normes environnementales pour la gestion des déchets incinérables. Cette directive marque le début d'une réglementation stricte visant à contrôler les émissions polluantes provenant des installations d'incinération.
                            </p>
                        </div>
                    </div>
                </div>

                <div class="accordion-item">
                    <button
                        class="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseTwo"
                        aria-expanded="false"
                        aria-controls="collapseTwo"
                    >
                        2004 : Adoption de la directive européenne relative aux emballages
                    </button>
                    <div
                        id="collapseTwo"
                        class="accordion-collapse collapse"
                        data-bs-parent="#FaqAccordion"
                    >
                        <div class="accordion-body">
                            <p>
                                L'année 2004 a été marquée par l'adoption d'une directive européenne visant à réduire l'impact environnemental des emballages. Cette directive impose des objectifs de réduction, de réutilisation et de recyclage des emballages, encourageant ainsi la transition vers une économie circulaire.
                            </p>
                        </div>
                    </div>
                </div>

                <div class="accordion-item">
                    <button
                        class="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseThree"
                        aria-expanded="false"
                        aria-controls="collapseThree"
                    >
                        2005 : Mise en place du Plan national prévention des déchets (PNPPD)
                    </button>
                    <div
                        id="collapseThree"
                        class="accordion-collapse collapse"
                        data-bs-parent="#FaqAccordion"
                    >
                        <div class="accordion-body">
                            <p>
                                En 2005, la France a lancé le Plan national prévention des déchets (PNPPD), une initiative visant à réduire la quantité de déchets produits et à promouvoir des pratiques de consommation durables. Ce plan encourage la prévention à la source, le réemploi et le recyclage des déchets.
                            </p>
                        </div>
                    </div>
                </div>

                <div class="accordion-item">
                    <button
                        class="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseFour"
                        aria-expanded="false"
                        aria-controls="collapseFour"
                    >
                        2012 : Adoption de la loi de Grenelle rendant obligatoire le tri aux entreprises de 50 employés
                    </button>
                    <div
                        id="collapseFour"
                        class="accordion-collapse collapse"
                        data-bs-parent="#FaqAccordion"
                    >
                        <div class="accordion-body">
                            <p>
                                La loi de Grenelle de l'Environnement de 2012 a introduit des mesures visant à renforcer la gestion des déchets en entreprise. Elle impose aux entreprises de plus de 50 employés l'obligation de trier leurs déchets, favorisant ainsi une meilleure gestion des ressources et la réduction de l'impact environnemental.
                            </p>
                        </div>
                    </div>
                </div>

                <div class="accordion-item">
                    <button
                        class="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseFive"
                        aria-expanded="false"
                        aria-controls="collapseFive"
                    >
                        2014 : Obligation pour les entreprises de plus de 100 salariés de valoriser leurs déchets à partir de 2016
                    </button>
                    <div
                        id="collapseFive"
                        class="accordion-collapse collapse"
                        data-bs-parent="#FaqAccordion"
                    >
                        <div class="accordion-body">
                            <p>
                                À partir de 2016, les entreprises de plus de 100 salariés sont tenues de mettre en place des dispositifs de valorisation de leurs déchets, conformément à la législation environnementale en vigueur. Cette mesure vise à réduire l'enfouissement des déchets et à promouvoir le recyclage et la valorisation matière.
                            </p>
                        </div>
                    </div>
                </div>

                <div class="accordion-item">
                    <button
                        class="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseSix"
                        aria-expanded="false"
                        aria-controls="collapseSix"
                    >
                        2016 : Obligation pour les entreprises de plus de 100 employés de trier
                    </button>
                    <div
                        id="collapseSix"
                        class="accordion-collapse collapse"
                        data-bs-parent="#FaqAccordion"
                    >
                        <div class="accordion-body">
                            <p>
                                En 2016, les entreprises de plus de 100 employés sont légalement tenues de trier leurs déchets, contribuant ainsi à la réduction de l'enfouissement et à la promotion du recyclage. Cette mesure vise à améliorer la gestion des déchets en entreprise et à favoriser une économie circulaire.
                            </p>
                        </div>
                    </div>
                </div>

                <div class="accordion-item">
                    <button
                        class="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseSeven"
                        aria-expanded="false"
                        aria-controls="collapseSeven"
                    >
                        2018 : Adoption de la loi AGEC (Anti-Gaspillage pour une Économie Circulaire)
                    </button>
                    <div
                        id="collapseSeven"
                        class="accordion-collapse collapse"
                        data-bs-parent="#FaqAccordion"
                    >
                        <div class="accordion-body">
                            <p>
                                La loi AGEC, adoptée en 2018, vise à lutter contre le gaspillage et à favoriser une économie circulaire en imposant des mesures de prévention, de réemploi et de recyclage des déchets. Cette loi renforce les obligations des entreprises en matière de gestion des déchets et encourage l'adoption de pratiques durables.
                            </p>
                        </div>
                    </div>
                </div>

                <div class="accordion-item">
                    <button
                        class="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapse8"
                        aria-expanded="false"
                        aria-controls="collapse8"
                    >
                        2020 : Entrée en vigueur de l'obligation de tri des biodéchets
                    </button>
                    <div
                        id="collapse8"
                        class="accordion-collapse collapse"
                        data-bs-parent="#FaqAccordion"
                    >
                        <div class="accordion-body">
                            <p>
                                À partir de 2020, les entreprises sont tenues de trier et de valoriser leurs biodéchets, contribuant ainsi à la réduction des émissions de gaz à effet de serre et à la préservation des ressources naturelles. Cette mesure vise à promouvoir le compostage et la valorisation énergétique des biodéchets.
                            </p>
                        </div>
                    </div>
                </div>

                <div class="accordion-item">
                    <button
                        class="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapse9"
                        aria-expanded="false"
                        aria-controls="collapse9"
                    >
                        2022 : Renforcement de l'obligation de tri des biodéchets pour les entreprises de plus de 100 personnes
                    </button>
                    <div
                        id="collapse9"
                        class="accordion-collapse collapse"
                        data-bs-parent="#FaqAccordion"
                    >
                        <div class="accordion-body">
                            <p>
                                En 2022, les entreprises de grande taille sont soumises à un renforcement de l'obligation de tri des biodéchets, afin de promouvoir une gestion responsable des déchets organiques. Cette mesure vise à réduire l'enfouissement des biodéchets et à favoriser leur valorisation.
                            </p>
                        </div>
                    </div>
                </div>

                <div class="accordion-item">
                    <button
                        class="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapse10"
                        aria-expanded="false"
                        aria-controls="collapse10"
                    >
                        2024 : Obligation du tri des déchets quel que soit l'effectif
                    </button>
                    <div
                        id="collapse10"
                        class="accordion-collapse collapse"
                        data-bs-parent="#FaqAccordion"
                    >
                        <div class="accordion-body">
                            <p>
                                À partir de 2024, toutes les entreprises, quelle que soit leur taille, sont tenues de trier leurs déchets, marquant ainsi une étape importante vers une gestion plus durable des ressources et une économie circulaire. Cette mesure vise à généraliser le tri des déchets et à encourager l'adoption de pratiques responsables en matière de gestion des déchets.
                            </p>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </div>
</div>
