<header class="main-header-area">

    <div class="main-top-header">
        <div class="container-fluid">
            <div class="row align-items-center">
                <div class="col-lg-7 col-md-7">
                    <ul class="top-header-information">
                        <li>
                            <span>Téléphone: </span> <a href="tel:4234543123678">+4 234-543 123 678</a>
                        </li>
                        <li>
                            <span>Email: </span> <a href="mailto:info@levi.com">info@levi.com</a>
                        </li>
                    </ul>
                </div>

                <div class="col-lg-5 col-md-5">
                    <ul class="top-header-social">
                        <li>
                            <a href="https://www.facebook.com/sharer/sharer.php?app_id=309437425817038&sdk=joey&u=http%3A%2F%2Fwww.dmt-recyclage.fr/accueil-recyclage-de-dechet-paris.php"
                               target="_blank">
                                <i class='bx bxl-facebook'></i>
                            </a>
                        </li>
                        <li>
                            <a href="https://twitter.com/share"
                               data-url="http://www.dmt-recyclage.fr/accueil-recyclage-de-dechet-paris.php"
                               target="_blank">
                                <i class='bx bxl-twitter'></i>
                            </a>
                        </li>
                        <li>
                            <a href="https://plus.google.com/share?url=http%3A%2F%2Fwww.dmt-recyclage.fr/accueil-recyclage-de-dechet-paris.php%2F%23.VDP-D0ol2xo.google_plusone_share&t=Neue+mode+,+grossiste+tissus+et+mercerie+France+Belgique."
                               target="_blank">
                                <i class='bx bxl-google-plus'></i>
                            </a>
                        </li>
                        <li>
                            <a href="https://www.linkedin.com/uas/connect/user-signin?session_redirect=https%3A%2F%2Fwww%2Elinkedin%2Ecom%2Fcws%2Fshare%3FisFramed%3Dfalse%26lang%3Den_US%26url%3Dhttp%253A%252F%252Fwww.dmt-recyclage.fr/accueil-recyclage-de-dechet-paris.php%26original_referer%3Dhttp%253A%252F%252F127%252E0%252E0%252E1%252Fneue-mode%252Ebe%252Fv1%252Faccueil-grossiste-tissus-france-belgique%252Ephp%26token%3D%26_ts%3D1412693201204%252E9148"
                               target="_blank">
                                <i class='bx bxl-linkedin'></i>
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>

    <div class="main-navbar">
        <div class="container-fluid">
            <nav class="navbar navbar-style-three navbar-expand-lg navbar-light">
                <div class="logo">
                    <a (click)="scrollTo('home')" class="pointer">
                        <img src="assets/img/logo.png" class="black-logo" alt="Logo">
                    </a>
                </div>
                <button class="navbar-toggler" type="button" data-bs-toggle="collapse"
                        data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent"
                        aria-expanded="false" aria-label="Toggle navigation">
                    <span class="navbar-toggler-icon"></span>
                </button>
                <div class="collapse navbar-collapse" id="navbarSupportedContent">
                    <ul class="navbar-nav">
                        <li class="nav-item">
                            <a routerLink="[]" queryParams="{scrollTo: 'home'}" queryParamsHandling="merge"
                               class="nav-link  pointer">
                                Accueil
                            </a>
                        </li>
                        <li class="nav-item dropdown">
                            <a class="nav-link  dropdown-toggle" href="#" data-bs-toggle="dropdown">DMT recyclage</a>
                            <ul class="dropdown-menu">
                                <li><a class="dropdown-item pointer" routerLink="[]" queryParams="{scrollTo: 'valor'}"
                                       queryParamsHandling="merge">Nos valeurs</a></li>
                                <li><a class="dropdown-item pointer" routerLink="[]"
                                       queryParams="{scrollTo: 'engagements'}" queryParamsHandling="merge">Nos
                                    engagements</a></li>
                                <li><a class="dropdown-item pointer" routerLink="[]"
                                       queryParams="{scrollTo: 'references'}" queryParamsHandling="merge">Nos
                                    références</a></li>
                                <li><a class="dropdown-item pointer" routerLink="[]"
                                       queryParams="{scrollTo: 'your_are'}" queryParamsHandling="merge">Vous etes?</a>
                                </li>
                            </ul>

                        </li>
                        <!--  <li class="nav-item dropdown">
                              <a class="nav-link  dropdown-toggle" href="#" data-bs-toggle="dropdown">Nos activités</a>
                              <ul class="dropdown-menu">
                                  <li><a class="dropdown-item pointer" routerLink="[]" queryParams="{scrollTo: 'valor'}"
                                         queryParamsHandling="merge">Collecte & recyclage</a></li>
                                  <li><a class="dropdown-item pointer" routerLink="[]"
                                         queryParams="{scrollTo: 'engagements'}" queryParamsHandling="merge">Location de
                                      benne</a></li>
                                  <li><a class="dropdown-item pointer" routerLink="[]"
                                         queryParams="{scrollTo: 'references'}" queryParamsHandling="merge">Démantèlement
                                      industriel</a></li>
                                  <li><a class="dropdown-item pointer" routerLink="[]"
                                         queryParams="{scrollTo: 'your_are'}" queryParamsHandling="merge">Débarras et
                                      Déblaiement</a></li>
                              </ul>

                          </li> -->
                        <li class="nav-item">
                            <a [routerLink]="['/activities']" class="nav-link">
                                Nos activités
                            </a>
                        </li>
                        <li class="nav-item">
                            <a href="#blog" class="nav-link">
                                FAQ
                            </a>
                        </li>
                        <li class="nav-item">
                            <a href="#contact" class="nav-link">
                                Actualités
                            </a>
                        </li>
                        <li class="nav-item">
                            <a href="#contact" fragment="contant-form" class="nav-link">
                                Contact/Devis
                            </a>
                        </li>
                    </ul>
                    <!--
                    <div class="others-option">
                        <div class="d-flex align-items-center">
                            <div class="option-item">
                                <a href="#" class="log-in">Log In</a>
                            </div>
                            <div class="option-item">
                                <a href="#" class="sign-up">Sign Up</a>
                            </div>
                        </div>
                    </div>
                    -->
                </div>
            </nav>
        </div>
    </div>

</header>

<div id="home" class="confidence-home-area-wrap">
    <div class="container">
        <div class="confidence-home-wrap-content">
            <!--            <span class="sub-title">Welcome to Levi</span>-->
            <h1 class="title">DMT Recyclage</h1>
            <p>Bienvenue chez DTM Recyclage, votre partenaire expert pour la manutention et le transport responsables de
                vos déchets. Nous nous engageons à offrir des solutions durables, contribuant ainsi à la préservation de
                notre environnement.</p>

            <ul class="confidence-home-btn">
                <li>
                    <a [routerLink]="['/contact']" fragment="contant-form" routerLinkActive="router-link-active"  class="main-default-btn">Demander un devis</a>
                </li>

            </ul>
        </div>
    </div>
</div>
