<div id="home" class="faq-home-area">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="choice-home-content">
                    <h1>Foire aux questions</h1>
                    <p class="text-white">Vous avez des questions ? Nous avons les réponses !</p>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="faq-style-area ptb-100">
    <div class="container">
        <div class="main-section-title">
            <span class="sub-title">FAQ</span>
            <h2>Besoin d'aide ? Consultez les questions fréquentes</h2>
        </div>

        <div class="faq-style-accordion">
            <div class="accordion" id="FaqAccordion">
                <div class="accordion-item">
                    <button class="accordion-button" type="button" data-bs-toggle="collapse"
                            data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                        Votre personnel est-il formé pour assurer en toute sécurité les missions qui lui sont confiées ?
                    </button>
                    <div id="collapseOne" class="accordion-collapse collapse show" data-bs-parent="#FaqAccordion">
                        <div class="accordion-body">
                            <p>
                                Notre personnel est formé par nos soins aux techniques de manutention. De plus, il est
                                sensibilisé aux particularités des métiers l’environnement. Formé sur les différentes
                                qualités de déchets collectés, il réalise le tri sélectif directement sur le site du
                                client.

                                Enfin, chaque opération est encadrée par un chef d’équipe qualifié et formé à
                                l’organisation des chantiers.
                            </p>
                        </div>
                    </div>
                </div>

                <div class="accordion-item">
                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                            data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                        Quel sont vos délais pour la réalisation des prestations après accord ?
                    </button>
                    <div id="collapseTwo" class="accordion-collapse collapse" data-bs-parent="#FaqAccordion">
                        <div class="accordion-body">
                            <p>
                                Notre réactivité est notre atout majeur. Nous répondons en moyenne entre 24 et 48 h dès
                                l’obtention du bon pour accord.

                                Seules les interventions sur déchets spéciaux nécessitent des délais d’intervention plus
                                longs en raison d’une règlementation et de conditions d’acheminement spécifiques.
                            </p>
                        </div>
                    </div>
                </div>

                <div class="accordion-item">
                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                            data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                        Respectez-vous la législation en vigueur pour la manutention et le transport des déchets ?
                    </button>
                    <div id="collapseThree" class="accordion-collapse collapse" data-bs-parent="#FaqAccordion">
                        <div class="accordion-body">


                            <p>Dans le cadre de nos services de transport, nous disposons des agréments nécessaires pour
                                le déplacement des déchets, lesquels ont été obtenus auprès de la préfecture. Nous
                                veillons également au respect des tonnages, évitant ainsi toute surcharge des
                                véhicules.</p>

                            <p> Nous adaptons nos véhicules en fonction des matières transportées. Par exemple, lorsque
                                nous manipulons des produits toxiques, les véhicules utilisés sont conformes à la
                                réglementation ADR (Accord européen relatif au transport international des marchandises
                                dangereuses par route).</p>

                            <p> En ce qui concerne la manutention, nos collaborateurs sont équipés de manière adéquate
                                pour toutes les missions qui leur incombent. Cela inclut l'utilisation de chaussures de
                                sécurité, de casques, de gants adaptés, de masques, de baudriers, de lunettes de
                                sécurité, et plus encore. Notre engagement formel est de ne jamais faire travailler des
                                collaborateurs sans contrat, évitant ainsi tout recours au travail non déclaré,
                                également appelé "au noir".</p>

                            <p> Enfin, en ce qui concerne les déchets collectés, chaque type de déchet est accompagné
                                d'un bordereau de suivi de déchet (BSD) garantissant une traçabilité efficace de bout en
                                bout.
                            </p>
                        </div>
                    </div>
                </div>

                <div class="accordion-item">
                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                            data-bs-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
                        Respectez-vous les normes environnementales en vigueur ?
                    </button>
                    <div id="collapseFour" class="accordion-collapse collapse" data-bs-parent="#FaqAccordion">
                        <div class="accordion-body">
                            <p>
                                Le respect de l’environnement est une des valeurs fortes de notre entreprise. Nous nous
                                engageons donc à recycler tout ce qui peut l’être et faire traiter vos déchets dans des
                                centres de traitement certifiés ISO 14001 dans le respect de la législation en vigueur
                                (loi du 15 juillet 1975 sur le tri et le traitement des déchets).
                            </p>
                        </div>
                    </div>
                </div>

                <div class="accordion-item">
                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                            data-bs-target="#collapseFive" aria-expanded="false" aria-controls="collapseFive">
                        Comment assurez-vous la traçabilité des déchets collectés chez vos clients ?
                    </button>
                    <div id="collapseFive" class="accordion-collapse collapse" data-bs-parent="#FaqAccordion">
                        <div class="accordion-body">
                            <p>
                                Nous informons systématiquement nos clients des différents exutoires utilisés pour faire
                                traiter les déchets collectés sur leur site.

                                Un bordereau de suivi des déchets (BSD) est ensuite proposé, afin d’assurer la
                                traçabilité du déchet depuis sa collecte jusqu’au centre de tri auquel il est remis.
                            </p>
                        </div>
                    </div>
                </div>

                <div class="accordion-item">
                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                            data-bs-target="#collapseSix" aria-expanded="false" aria-controls="collapseSix">
                        Réalisez-vous des reportings sur les actions menées chez vos clients ?
                    </button>
                    <div id="collapseSix" class="accordion-collapse collapse" data-bs-parent="#FaqAccordion">
                        <div class="accordion-body">
                            <p>
                                Nous proposons des reportings précis de nos prestations, à la demande de nos clients.

                                Nous pouvons par exemple mettre en place un reporting mensuel sous format EXCEL sur
                                lequel figurera l’ensemble des dates et des prestations réalisées sur votre site ainsi
                                que les qualités et les quantités de déchets collectés.
                            </p>
                        </div>
                    </div>
                </div>

                <div class="accordion-item">
                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                            data-bs-target="#collapseSeven" aria-expanded="false" aria-controls="collapseSeven">
                        Comment sécurisez-vous vos chantiers ?
                    </button>
                    <div id="collapseSeven" class="accordion-collapse collapse" data-bs-parent="#FaqAccordion">
                        <div class="accordion-body">
                            <p> Nous sommes très attentifs au respect des normes de sécurité, et ce à plusieurs niveaux
                                :</p>
                            <ul>
                                <li>
                                    Pour la sécurité des personnes : nous balisons systématiquement la zone de chantier
                                    dans les locaux d’intervention, nos collaborateurs bénéficient de tout l’équipement
                                    nécessaire à leur protection physique (chaussures de sécurité, casques, gants
                                    adaptés, masques, baudriers, lunettes de sécurité…),

                                </li>
                                <li>
                                    Pour la sécurisation des locaux : balisage de la zone de travaux, sécurisation de
                                    l’environnement (validation des points d’accès, hauteur – surface - tonnage…), nous
                                    protégeons les surfaces fragiles par des systèmes de couvertures, bâches plastiques
                                    ou cartons,

                                </li>
                                <li>
                                    Pour la sécurité du transport : nous disposons de toutes les habilitations pour le
                                    transport de marchandises dangereuses et non-dangereuses. Tout notre personnel amené
                                    à transporter des produits dangereux bénéficie d’une formation ADR (Accord européen
                                    relatif au transport international des marchandises dangereuses par route).

                                </li>
                            </ul>
                        </div>
                    </div>
                </div>

                <div class="accordion-item">
                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                            data-bs-target="#collapse8" aria-expanded="false" aria-controls="collapse8">
                        Quel-est votre processus d’organisation ?
                    </button>
                    <div id="collapse8" class="accordion-collapse collapse" data-bs-parent="#FaqAccordion">
                        <div class="accordion-body">
                            <p>
                                Nous commençons toujours par la réalisation d’un audit ou d’une estimation du travail à
                                effectuer sur place. Un devis vous est ensuite envoyé. Une fois validé, nous entamons la
                                prestation 24 à 48 H après avoir reçu votre bon pour accord.

                                Les déchets collectés sont ensuite transférés vers des exutoires certifiés ISO 14001
                                conformément à la réglementation. En ce qui concerne les déchets dangereux, un bordereau
                                de suivi des déchets (BSD) vous est envoyé à l’issue de la prestation.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </div>
</div>
