import {Component, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';

@Component({
  selector: 'app-professionnal',
  templateUrl: './professionnal.component.html',
  styleUrls: ['./professionnal.component.scss']
})
export class ProfessionnalComponent  implements OnInit {

    constructor(private route: ActivatedRoute) {

    }

    scrollTo(element: any): void {
        (document.getElementById(element) as HTMLElement).scrollIntoView({
            behavior: 'smooth',
            block: 'start',
            inline: 'nearest'
        });
    }

    ngOnInit(): void {
        this.route.queryParams.subscribe((params) => {
                if (params.scrollTo) {
                    this.scrollTo(params.scrollTo);
                }
            }
        );
    }
}
