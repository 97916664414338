import { Component } from '@angular/core';

@Component({
  selector: 'app-reglementations',
  templateUrl: './reglementations.component.html',
  styleUrls: ['./reglementations.component.scss']
})
export class ReglementationsComponent {

}
