<div id="home" class="news-home-area">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="choice-home-content">
                    <h1>Actualités</h1>
                    <p class="text-white">
                        Retrouvez ici toutes les actualités de l'association
                    </p>
                </div>
            </div>
        </div>
    </div>
</div>

<div id="blog" class="blog-style-area pt-100 pb-100">
    <div class="container">
        <div class="main-section-title">
            <span class="sub-title">News & Blog</span>
            <h2>Actualités et Points de Vue en Vedette
            </h2>
        </div>

        <div class="row">
            <div class="col-lg-6 col-md-12">
                <div class="blog-style-left-card">
                    <div class="blog-image">
                        <a href="#"><img src="assets/img/img_3.png" alt="image"></a>

                        <div class="tag">News</div>
                    </div>
                    <div class="blog-content">
                        <ul class="post-meta d-flex justify-content-between align-items-center">

                            <li>
                                <i class='bx bxs-calendar'></i> Le jeudi 21 Décembre 2017
                            </li>

                            <li>
                                <i class='bx bx-message-dots'></i> No Comments
                            </li>
                        </ul>
                        <h3>
                            <a href="#">Comment (bien) gérer le recyclage des déchets en entreprise ?</a>
                        </h3>
                        <p>Maintenant que vous avez convaincu vos associés de mettre en place le recyclage des déchets
                            dans votre ...
                        </p>
                    </div>
                </div>
            </div>

            <div class="col-lg-6 col-md-12">
                <div class="blog-style-right-card">
                    <div class="card-box">
                        <div class="blog-content">
                            <div class="tag">News</div>
                            <ul class="post-meta">
                                <li>
                                    <i class='bx bxs-calendar'></i> Le lundi 28 Aout 2017
                                </li>

                                <li>
                                    <i class='bx bx-message-dots'></i> No Comments
                                </li>
                            </ul>
                            <h3>
                                <a href="#">DMT Recyclage arrive à Bordeaux</a>
                            </h3>
                            <p>Acteur majeur de la gestion des déchets en Ile-de-France depuis plusieurs années, DMT
                                Recyclage s’étend aujourd’hui sur le plan national.</p>

                        </div>
                    </div>

                    <div class="card-box">
                        <div class="blog-content">
                            <div class="tag">News</div>
                            <ul class="post-meta">
                                <li>
                                    <i class='bx bxs-calendar'></i> Le vendredi 25 Novembre 2016
                                </li>

                                <li>
                                    <i class='bx bx-message-dots'></i> No Comments
                                </li>
                            </ul>
                            <h3>
                                <a href="#">Décret sur le tri des déchets en entreprise : ça change quoi ?</a>
                            </h3>
                            <p>C'était dans l'air depuis quelque temps, c'est effectif depuis le 1er juillet : le décret
                                du 10 mars concernant...</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="container">
    <div class="row align-items-start">
        <div class="col-lg-6 col-md-12">
            <div class="blog-style-left-card">
                <div class="blog-image">
                    <a href="#"><img src="assets/img/img_18.png" alt="image"></a>

                    <div class="tag">News</div>
                </div>
                <div class="blog-content">
                    <ul class="post-meta d-flex justify-content-between align-items-center">

                        <li>
                            <i class='bx bxs-calendar'></i> Le mercredi 1er Octobre 2014
                        </li>

                        <li>
                            <i class='bx bx-message-dots'></i> No Comments
                        </li>
                    </ul>
                    <h3>
                        <a href="#">Prévenir ou réduire la production et la nocivité des déchets,</a>
                    </h3>
                    <p>Déjà en 1975, l’Etat se préoccupait de prévenir ou réduire la production et la nocivité des
                        déchets, notamment en agissant sur la fabrication et sur la distribution des produits.</p>
                </div>
            </div>
        </div>

        <div class="col-lg-6 col-md-12">
            <div class="blog-style-left-card">
                <div class="blog-image">
                    <a href="#"><img src="assets/img/img_19.png" alt="image"></a>

                    <div class="tag">News</div>
                </div>
                <div class="blog-content">
                    <ul class="post-meta d-flex justify-content-between align-items-center">


                        <li>
                            <i class='bx bxs-calendar'></i> Le lundi 1er Septembre 2014
                        </li>

                        <li>
                            <i class='bx bx-message-dots'></i> No Comments
                        </li>
                    </ul>
                    <h3>
                        <a href="#">Grenelle de l'environnement</a>
                    </h3>
                    <p>Il existe deux principales lois sur l’élimination des déchets et les installations classées pour
                        la protection de l’environnement </p>
                </div>
            </div>
        </div>
    </div>
    <br>
    <br>
    <div class="row align-items-start">
        <div class="col-lg-6 col-md-12">
            <div class="blog-style-left-card">
                <div class="blog-image">
                    <a href="#"><img src="assets/img/img_20.png" alt="image"></a>

                    <div class="tag">News</div>
                </div>
                <div class="blog-content">
                    <ul class="post-meta d-flex justify-content-between align-items-center">

                        <li>
                            <i class='bx bxs-calendar'></i> Le vendredi 1er Aout 2014
                        </li>

                        <li>
                            <i class='bx bx-message-dots'></i> No Comments
                        </li>
                    </ul>
                    <h3>
                        <a href="#">Grenelle 2 : une nouvelle loi en faveur de l’environnement</a>
                    </h3>
                    <p>À l’issue du premier Grenelle de l’environnement, une loi relative à sa mise en œuvre a été
                        votée(1), mais le Grenelle 2 a permis d’aller plus loin.</p>
                </div>
            </div>
        </div>
    </div>

</div>
<br>
<br>
<br>
<br>
<br>
<br>
<br>
